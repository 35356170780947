@import "../../assets/scss/breakpoints";
@import "../../assets/scss/mixins";
@import "../../assets/scss/vars";

.title {
  @include respond-to(l) {
    border: 1px solid;
    border-image: $primary_gradient 0 1 0 0;
    padding-bottom: 80px;
  }

  & > div {
    @include respond-to(l) {
      @include flex(column, center, center);
      height: 100%;
    }
  }

  & h1 {
    @include title;
    margin-bottom: 20px;
    @include respond-to(m) {
      margin-bottom: 30px
    }
    @include respond-to(l) {
      margin-bottom: 50px
    }
  }
}

.container svg {
  display: none;
  @include respond-to(l) {
    display: initial;
  }
}
