@import "src/assets/scss/vars";
@import "src/assets/scss/mixins";

.input, .main {
  display: none;

  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-size: 14px;

    &::before {
      box-sizing: border-box;
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      flex-grow: 0;
      background-color: transparent;
      margin-right: 8px;
      background-repeat: no-repeat;
      background-position: center center;
      border: 1px solid $secondary_text;
      border-radius: 3px
    }
  }

  &:checked + label::before {
    background-color: $secondary_text;
    background-image: url('../../../assets/images/checkbox.svg');
    border-radius: 3px
  }
}

.main {
  & + label {
    align-items: flex-start;
    @include respond-to(l) {
      align-items: center;
    }
  }

  &:checked + label::before {
    border: 1px solid transparent;
    background-color: transparent;
    background-image: url('../../../assets/images/done.svg');
  }
}


