@import "src/assets/scss/breakpoints";

.circle {
  transform: rotate(-90deg) scaleY(-1);
  width: 100px;
  height: 100px;
  @include respond-to(m) {
    width: 140px;
    height: 140px
  }
}
