@import "../../assets/scss/breakpoints";

.container {
  display: flex;
  width: 100%;
  text-align: center;
  margin-top: 17px;
  flex-grow: 1;
  @include respond-to(m) {
    margin-top: 22px;
  }
  @include respond-to(l) {
    margin-top: 36px;
    flex-direction: column
  }
}

.block {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  @include respond-to(l) {
    flex-direction: row;
    height: auto;
    flex-grow: 1;
    padding-bottom: 80px;
    & > div {
      width: 50%

    }
  }
}

.mobile {
  @include respond-to(l) {
    display: none;
  }
}

.desktop {
  display: none;
  @include respond-to(l) {
    display: block;
  }
}

.wrapper {
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  @include respond-to(l) {
    max-width: fit-content;
    width: initial;
  }
}

.content, .radioContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @include respond-to(l) {
    max-width: 388px
  }
}

.content {
  justify-content: space-between;
  @include respond-to(l) {
    justify-content: center
  }
}

.radioContent {
  justify-content: flex-start;
  @include respond-to(l) {
    justify-content: center
  }
}
