@import "./vars";

@mixin respond-to($media) {
  @if $media == s {
    @media only screen and (max-width: $small) { @content; }
  }
  @else if $media == m {
    @media screen and (min-width: $small + 1) { @content; }
  }
  @else if $media == l {
    @media screen and (min-width: $medium + 1) { @content; }
  }
}
