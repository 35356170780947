@import "../../assets/scss/breakpoints";

.progress {
  border-radius: 15px;
  margin-bottom: 15px;
  @include respond-to(m) {
    margin-bottom: 26px;
  }
  @include respond-to(l) {
    margin-bottom: 0
  }
}
