@import "../../assets/scss/vars";
@import "../../assets/scss/mixins";

.container {
  @include flex(column, space-between, center);
  max-width: 1366px;
  padding: 28px 15px 30px 15px;
  margin: 0 auto;

  @include respond-to(m) {
    padding: 40px 34px;
    flex-direction: row;
    justify-content: space-between;
  }

  @include respond-to(l) {
    padding: 50px 149px;
  }
}

.wrapper {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $border_violet;
  z-index: 1000;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

}

.info {
  @include flex(column, center, center);
  margin-bottom: 20px;

  @include respond-to(m) {
    flex-direction: row;
    justify-content: normal;
    margin-bottom: 0;
    margin-right: 28px;
  }
}

.infoText {
  max-width: 524px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;

  @include respond-to(m) {
    text-align: left;
  }
}

.infoIcon {
  fill: $primary_text;
  width: 32px;
  height: 32px;
}

.infoIconWrapper {
  display: inline-block;
  font-size: 0;
  flex-shrink: 0;
  margin-bottom: 10px;

  @include respond-to(m) {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.button, .button_wrapper {
  margin: 0;
  border-radius: 8px;
  max-width: 330px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  @include respond-to(m) {
    height: 50px;
  }
}

.button {
  width: 100%;
  line-height: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.button_wrapper {
  margin: 0;
  flex-shrink: 0;
  max-width: 220px;
  width: 100%;

  @include respond-to(m) {
    max-width: 180px;
  }

  @include respond-to(l) {
    max-width: 252px;
  }
}

.inline_link {
  color: $primary_text;
}