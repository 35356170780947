.contact {
  margin: 30px 0;
  width: 100%;
  padding: 16px;
  background: #edeef0;
  min-height: 100vh;
}
.contact a {
  color: blue;
}
.contact a:visited {
  color: purple;
}


.title {
  font-size: 24px;
  font-family: sans-serif;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
  color: black;
}

.text {
  font-size: 14px;
  font-family: sans-serif;
  line-height: 18px;
  text-align: center;
  color: black;
}

.text a {
  font-family: sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  text-decoration: none;
  color: black;
}
