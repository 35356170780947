@import "../../assets/scss/breakpoints";
@import "../../assets/scss/vars";

.choiceList {
  display: flex;
  justify-content: space-between;
}

.choiceImg {
  width: 56px;
  margin-bottom: 21px;
  @include respond-to(m) {
    width: 77px;
  }
}

.choice {
  width: 87px;
  cursor: pointer;

  @include respond-to(m) {
    width: 95px
  }

  &:hover {
    .choiceImg {
      filter: drop-shadow(0px 4px 20px rgba(255, 255, 255, 0.8))
    }
  }
}

.choiceDisabled {
  cursor: not-allowed;

  &:hover {
    .choiceImg {
      filter: none;
    }
  }
}

.choiceText {
  color: $secondary_text;
  @include respond-to(m) {
    font-size: 18px;
    color: $primary_text
  }
}
