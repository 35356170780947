@import "../../assets/scss/breakpoints";
@import "../../assets/scss/mixins";
@import "../../assets/scss/vars";

div.container {
  margin-top: 27px;
  align-items: center;
  @include respond-to(m) {
    margin-top: 50px;
  }
  @include respond-to(l) {
    margin-top: 60px;
  }
}
