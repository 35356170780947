@import "src/assets/scss/vars";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.block {
  margin-bottom: 30px;
  @include respond-to(m) {
    margin-bottom: 130px
  }
  @include respond-to(l) {
    margin-top: 70px;
    margin-bottom: 0
  }
}

.button, .disabled {
  width: 300px;
  padding: 10px;
  align-self: center;
  border-radius: 30px;
  outline: none;
  border: none;
  color: $primary_text;
  @include respond-to(m) {
    width: 340px
  }
  @include respond-to(l) {
    width: 380px
  }
}

.button {
  background: $primary_gradient;
  @include transition(all, 150ms, ease-in-out);

  &:active {
    @include scale(1.03)
  }
}

.disabled {
  cursor: not-allowed;
  background: $secondary_gradient
}

