@import "../../../assets/scss/mixins";
@import "../../../assets/scss/breakpoints";
@import "../../../assets/scss/vars";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  width: 330px;
  margin-top: 50px;
  @include respond-to(m) {
    width: 709px;
    margin-top: 80px;
  }
  @include respond-to(l) {
    width: 864px;
  }
}


.title {
  margin-bottom: 20px;
  color: $primary_text;
  @include title(24px, 28px, 32px);
  @include respond-to(m) {
    margin-bottom: 30px;
  }
}

.list {
  list-style-image: url("/assets/images/postQuiz/star_bullet.png");
  list-style-position: outside;

  & > li {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 140%;
    font-size: 14px;
    margin-bottom: 12px;
    @include respond-to(m) {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 20px;
      max-width: 432px;
    }
  }
}
