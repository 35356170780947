@import "../../../assets/scss/breakpoints";
@import "../../../assets/scss/vars";

.container {
  margin-bottom: 30px;
  width: 50%;
  @include respond-to(m) {
    width: 26%;
  }
  @include respond-to(l) {
    margin-bottom: 50px;
  }
}

.img {
  margin-bottom: 10px;
}

.title {
  font-family: opensans-semi, sans-serif;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 22px;
  @include respond-to(m) {
    font-size: 16px;
    line-height: 24px;
  }
}

.text {
  color: $secondary_text;
  font-size: 12px;
  line-height: 20px;
  @include respond-to(m) {
    max-width: 280px;
    font-size: 14px;
    line-height: 24px;
  }
}
