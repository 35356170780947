@font-face {
  font-family: "opensans-bold";
  src: url("../fonts/OpenSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "opensans-reg";
  src: url("../fonts/OpenSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "opensans-extra";
  src: url("../fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "opensans-italic";
  src: url("../fonts/OpenSans-Italic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "opensans-semi";
  src: url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-bold";
  src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-light";
  src: url("../fonts/Montserrat-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-medium";
  src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-reg";
  src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-semi";
  src: url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
