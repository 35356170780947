@import "../../../assets/scss/vars";
@import "../../../assets/scss/breakpoints";
@import "../../../assets/scss/mixins";

.link {
  display: block;
  padding: 12px;
  border-radius: 30px;
  color: $primary_text;
  background: $primary_gradient;
  @include transition(all, 100ms, ease-in-out);

  &:active {
    @include scale(1.03)
  }

  @include respond-to(m) {
    font-size: 18px;
    padding: 13px
  }
}

.linkDisabled {
  cursor: not-allowed;
  background: $secondary_gradient;

  &:active {
    @include scale(1)
  }
}
