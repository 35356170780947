@import "assets/scss/reset";
@import "assets/scss/fonts";
@import "assets/scss/mixins";
@import "assets/scss/vars";

body {
  margin: 0;
  font-family: opensans-reg, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary_text;
}
