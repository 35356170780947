@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.list_container {
  position: absolute;
  left: 0;
  width: 100%;
  @include flex(column, space-between, center);
  flex-grow: 1;
  z-index: 2;
  @include respond-to(l) {
    justify-content: center;
  }
}

.list {
  width: 100%;
  background: $primary_text;
  margin-top: 10px;
  border-radius: 10px;
  max-height: 265px;
  overflow: scroll;

  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @include respond-to(m) {
    max-height: 285px;
    max-width: 380px;
    margin: 10px auto;
  }
}

.list_item {
  text-align: left;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid $primary_border;
  color: #2F2E37;
  &:hover {
    background: #E2E4E7;
  }
}
