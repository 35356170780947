@import "src/assets/scss/vars";
@import "src/assets/scss/breakpoints";

.container {
  position: relative;
}

.input, .error {
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  color: $secondary_text;
  border: 1px solid $primary_border;
  border-radius: 25px;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 24px;

  &:focus {
    outline: none
  }

  @include respond-to(m) {
    font-size: 16px
  }
}

.error {
  border: 1px solid $error;
}

.errorText {
  text-align: left;
  color: $error;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 15px;
}

