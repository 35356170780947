@import "../../../assets/scss/breakpoints";
@import "../../../assets/scss/mixins";

.title {
  @include title(24px, 32px, 32px);
  line-height: 33px;
  margin-bottom: 30px;

  @include respond-to(m) {
    margin-bottom: 50px;
  }
  @include respond-to(l) {
    margin-bottom: 70px;
  }
}

.button_container {
  @include flex(column, center, center);
  width: 100%;
}

.button, .button_wrapper {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 8px;
  max-width: 330px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  @include respond-to(m) {
    height: 50px;
  }
}

.button_wrapper {
  margin-bottom: 40px;
  margin-top: 30px;
  @include respond-to(m) {
    margin-bottom: 54px;
    margin-top: 70px;
  }
  @include respond-to(l) {
    margin-bottom: 67px;
    margin-top: 80px;
  }
}
