.faq-background {
  background: #edeef0 !important;
}

.wrap {
  padding-bottom: 40px;
}

.faq {
  margin: 30px 0;
  width: 100%;
  padding: 16px;
  min-height: 100vh;
  background: #edeef0;
}
.faq a {
  color: blue;
}
.faq a:visited {
  color: purple;
}

.title {
  font-size: 24px;
  font-family: sans-serif;
  line-height: 28px;
  text-align: center;
  margin-bottom: 50px;
  color: black;
  font-weight: bolder;
}

.subtitle {
  font-size: 18px;
  font-family: sans-serif;
  line-height: 22px;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-weight: bold;
}

.text {
  font-size: 14px;
  font-family: sans-serif;
  line-height: 18px;
  text-align: center;
  margin-bottom: 30px;
  color: black;
}

.text a {
  font-family: sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  text-decoration: none;
  color: black;
}
