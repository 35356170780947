@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.title {
  color: $primary_text;
  margin-bottom: 20px;
  max-width: 708px;
  @include title(24px, 28px, 36px);
}

