@import "../../../assets/scss/vars";
@import "../../../assets/scss/mixins";

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  };
}

.container {
  outline: none;
  background: white;
  border-radius: 20px;
  padding: 20px 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 290px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid $primary_border;
}

.button {
  display: block;
  margin-left: auto;
  margin-bottom: 10px;
  color: $typography_black;
}

.price {
  color: $typography_black;
  font-family: opensans-bold, sans-serif;
  font-size: 18px;
  @include flex(row, space-between)
}

.spinner {
  color: $typography_black;
}

.text {
  color: $typography_black;
  margin: 15px auto;
  position: relative;

  &::before, &::after {
    background: #c4c4c4;
    content: "";
    display: block;
    height: 1px;
    width: 80px;
    position: absolute;
    top: 8px;
  }

  &::after {
    left: 25px
  }

  &::before {
    left: -90px;
  }
}

.payments_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 433px;
}

.hidden {
  visibility: hidden;
}
