@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.list {
  font-size: 14px;
  color: $secondary_text;
  line-height: 24px;
  margin-bottom: 5px;
  width: 280px;

  & > li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  @include respond-to(m) {
    width: 362px;
    & > li {
      font-size: 16px;
      margin-bottom: 15px
    }
  }
}
