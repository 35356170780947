@import "../../assets/scss/breakpoints";
@import "../../assets/scss/vars";

.text {
  font-size: 14px;
  margin-bottom: 44px;
  color: $secondary_text;
  line-height: 24px;
  @include respond-to(m) {
    font-size: 16px;
    margin-bottom: 70px;
  }
  @include respond-to(l) {
    font-size: 18px;
    margin: 0 auto 44px auto
  }
}
